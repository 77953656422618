import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  debounce,
  Fab,
  Grid2,
  Typography,
  LinearProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import BlogDto from "../Dtos/BlogDto";
import theme from "../theme/theme";
import axios from "axios";
import { Helmet } from "react-helmet-async";

export default function Home() {
  const [blogs, setBlogs] = useState<BlogDto[]>([]);
  const [page, setPage] = useState(0); // Sayfa numarası
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(getResponsivePageSize());
  const [total, setTotal] = useState<number | null>(null); // Toplam kayıt sayısı

  // Ekran genişliğine göre sayfa boyutunu belirler
  function getResponsivePageSize() {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1200) {
      return 4; // Büyük ekranlarda 4 içerik
    } else if (screenWidth >= 900) {
      return 3; // Orta boyutlu ekranlarda 3 içerik
    } else if (screenWidth >= 600) {
      return 2; // Küçük ekranlarda 2 içerik
    } else {
      return 1; // Mobilde 1 içerik
    }
  }

  // Dinamik içerik yükleme fonksiyonu
  const GetBlogs = async (isInitialLoad = false) => {
    if (isInitialLoad) {
      setPage(1);
    }
    if (loading || (total !== null && blogs.length >= total)) return; // Tüm içerik yüklendiyse çık

    setLoading(true);
    try {
      const currentPage = isInitialLoad ? 0 : page; // İlk yüklemede page=0
      const res = await axios.get(
        `https://api.degeregitimiakademisi.com/getpost?ps=${pageSize}&p=${currentPage}`
      );

      setBlogs((prevBlogs) =>
        isInitialLoad ? res.data.posts : [...prevBlogs, ...res.data.posts]
      );
      setTotal(res.data.totalCount); // Toplam kayıt sayısını ayarla
      if (!isInitialLoad) setPage((prevPage) => prevPage + 1); // Sayfa artır
    } catch (error) {
      console.error("Bloglar yüklenirken bir hata oluştu:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = debounce(() => {
    const isNearBottom =
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight - 700;

    if (isNearBottom && !loading && blogs.length < total!) {
      GetBlogs();
    }
  }, 300); // 300ms gecikme

  function getDynamicScrollThreshold(): number {
    const screenHeight = window.innerHeight;
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1200) {
      return screenHeight - 600; // Büyük ekranlarda alt limit daha düşük
    } else if (screenWidth >= 900) {
      return screenHeight - 500; // Orta ekranlarda alt limit orta
    } else if (screenWidth >= 600) {
      return screenHeight - 400; // Küçük ekranlarda alt limit
    } else {
      return screenHeight - 300; // Mobilde alt limit daha düşük
    }
  }

  // Sayfa boyutunu değiştirince çağrılan fonksiyon
  const handleResize = () => {
    const newPageSize = getResponsivePageSize();
    if (newPageSize !== pageSize) {
      setPageSize(newPageSize);
      setPage(0); // Sayfayı sıfırla
      setBlogs([]); // Blogları sıfırla
      setTotal(null); // Toplam sayıyı sıfırla
      GetBlogs(false); // Yeniden yükleme yap
    }
  };

  // HTML etiketlerini temizleyerek metin döndüren yardımcı fonksiyon
  function getSubstringWithoutTags(
    htmlString: string,
    startIndex: number,
    endIndex: number
  ): string {
    const container = document.createElement("div");
    container.innerHTML = htmlString;
    const textContent = container.textContent || "";
    return textContent.substring(startIndex, endIndex) + "...";
  }

  // İlk yükleme sırasında çağrılan useEffect
  useEffect(() => {
    GetBlogs(true); // İlk yüklemede içerikleri getir
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, blogs.length, total]);

  return (
    <div>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1584571816187109"
          crossOrigin="anonymous"
        ></script>
        <title>Anasayfa | Değer Eğitimi Akademisi</title>
        <meta
          name="description"
          content="Değer Eğitimi Akademisi - Şevval Tiryaki"
        />
        <meta
          name="keywords"
          content="dini oyunlar,çocuklar için dini eğitim,dini etkinlikler"
        />
        <meta
          property="og:title"
          content="Anasayfa | Değer Eğitimi Akademisi"
        />
        <meta
          property="og:description"
          content="Dini Oyunlar,Dini Etkinlikler ve Dini Özel günler için e-kitaplar içeren web sitesi"
        />
        {/* <meta property="og:image" content="https://example.com/home-image.jpg" /> */}
      </Helmet>
      {/* Yükleme sırasında LinearProgress */}
      {loading && (
        <Box
          sx={{
            width: "100%",
            position: "fixed",
            top: 0,
            left: 0,
          }}
        >
          <LinearProgress />
        </Box>
      )}
      <Box
        sx={{
          display: "",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "95vh",
          mb: 10,
          mx: "auto",
          width: "90%",
        }}
      >
        <Grid2
          container
          spacing={6}
          justifyContent="left"
          alignItems="flex-start"
        >
          {blogs.map((item, index) => (
            <Grid2
              key={index}
              size={{ lg: 5, md: 4, sm: 6, xl: 3, xs: 12 }}
              component={"div"}
              alignItems="flex-start"
            >
              <Card
                sx={{
                  maxWidth: "100%",
                  backgroundColor: theme.palette.secondary.light,
                  boxShadow: theme.shadows[1],
                }}
              >
                <a href={"/makale/" + item.link + "/" + item.guid}>
                  <CardMedia
                    sx={{ height: 250 }}
                    image={`../../Upload/Posts/${item.image}`}
                    title={item.image_alt}
                  />
                </a>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    <a href={"/makale/" + item.link + "/" + item.guid}>
                      {item.title}
                    </a>
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary" }}
                    component={"div"}
                    dangerouslySetInnerHTML={{
                      __html: getSubstringWithoutTags(item.content, 0, 200),
                    }}
                  ></Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <Fab
                    variant="extended"
                    size="medium"
                    color="secondary"
                    href={"/makale/" + item.link + "/" + item.guid}
                  >
                    Devamı
                  </Fab>
                </CardActions>
              </Card>
            </Grid2>
          ))}
        </Grid2>
        {loading && (
          <Typography variant="body2" sx={{ color: "text.secondary", mt: 2 }}>
            Yükleniyor...
          </Typography>
        )}
        {!loading && blogs.length >= total! && total !== null && (
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", mt: 2, textAlign: "center" }}
          >
            Tüm içerikler yüklendi.
          </Typography>
        )}
      </Box>
    </div>
  );
}
