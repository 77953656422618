// Layout.js
import { Outlet } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

function Layout() {
  return (
    <div>
    
      <Header></Header>
      <main>
        <Outlet /> {/* Dinamik içerik burada yüklenecek */}
      </main>{" "}
      <Footer></Footer>
    </div>
  );
}

export default Layout;
