"use client";

import {
  AppBar,
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu"; // Hamburger ikonu
import CloseIcon from "@mui/icons-material/Close"; // Çıkış ikonu
import React, { useState } from "react";
import logo from "../img/logo.png";
import theme from "../theme/theme";
import styles from "./footer.module.css";

export default function Header() {
  const navItems = [
    { label: "ANASAYFA", link: "/" },
    { label: "E-KİTAPLAR", link: "/e-kitaplar" },
    { label: "MAKALELER", link: "/makaleler" },
  ];

  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box
      sx={{
        textAlign: "center",
        background: theme.palette.primary.main,
        height: "100%",
        color: "white",
        width:'100%'
      }}
      onClick={handleDrawerToggle}
    >
      <IconButton
        sx={{
          color: "white",
          position: "absolute",
          top: 10,
          right: 10,
          
        }}
      >
        <CloseIcon />
      </IconButton>
      <a href="/">
      <Box
  sx={{
    display: "inline-block", // Sadece img boyutunda tıklanabilirlik sağlar
    mx: "auto", // Ortalanması için
    my: 4, // Üst ve alt boşluk

  }}
>
  <a href="/">
  <Avatar
      alt="Değerler Eğitimi"
      src={logo}
      sx={{
        width: 180,
        height: 100,
      }}
    />
  </a>
</Box>
      </a>
      <List>
        {navItems.map((item) => (
          <ListItem
            key={item.label}
            component="a"
            href={item.link}
            sx={{
              textAlign: "center",
              "&:hover": { background: theme.palette.secondary.main },
            }}
          >
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{
                fontSize: 16,
                fontWeight: "bold",
                color: "white",
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <div className={styles.content}>
        <AppBar
          component="nav"
          sx={{
            background: theme.gradients.primaryGradient,
            p: 2,
            position: "sticky",
          }}
        >
          <Toolbar>
            {isMobile && (
              <IconButton
                color="inherit"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}
              >
                <MenuIcon fontSize="large" sx={{color:'#707070'}} />
              </IconButton>
            )}

            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Avatar
                alt="Değerler Eğitimi"
                src={logo}
                sx={{ width: 150, height: 80 }}
              />
            </Typography>

            {!isMobile && (
              <Box>
                {navItems.map((item) => (
                  <>
                  <Button
                    key={item.label}
                    href={item.link}
                    sx={{
                      color: "#d89aeb",
                      mx: 1,
                      fontSize: 16,
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    {item.label}
                  </Button>
                  
                  </>
                ))}
              </Box>
            )}
          </Toolbar>
        </AppBar>

        <Drawer
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "50%",
              background: theme.palette.primary.main,
            },
          }}
        >
          {drawer}
        </Drawer>
        {/* Toolbar spacing below */}
        <Toolbar />
      </div>
    </>
  );
}
