"use client";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Fab,
  Grid2,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
// import ebookImage from '../img/ebook.jpg'
import camiImage from "../img/cami-onu.jpg";
import theme from "../theme/theme";
import { Parallax } from "react-parallax";
import BlogDto from "../Dtos/BlogDto";
import { Helmet } from "react-helmet-async";

export default function Home() {
  const [blogs, setBlogs] = useState<BlogDto[]>([]);

  const GetBlogs = async () => {
    try {
      const res = await axios.get(
        `https://api.degeregitimiakademisi.com/getpost?ps=100&p=0`
      );
      console.log(res);
      setBlogs(res.data.posts);
    } catch (error) {
      console.log(error);
    }
  };

  function getSubstringWithoutTags(
    htmlString: string,
    startIndex: number,
    endIndex: number
  ): string {
    const container = document.createElement("div");

    container.innerHTML = htmlString;
    const textContent = container.textContent;

    return textContent!.substring(startIndex, endIndex) + "...";
  }

  useEffect(() => {
    GetBlogs();
  }, []);

  return (
    <>
      <Helmet>
      <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1584571816187109"
          crossOrigin="anonymous"
        ></script>
        <title>Makaleler | Değer Eğitimi Akademisi</title>
        <meta
          name="description"
          content="Çocuklar için dini eğitim ve dini etkinlikler,oyunlar gibi konularda yazılan makalelerimiz"
        />
        <meta
          name="keywords"
          content="dini oyunlar,çocuklar için dini eğitim,dini etkinlikler"
        />
        <meta
          property="og:title"
          content="Makaleler | Değer Eğitimi Akademisi"
        />
        <meta
          property="og:description"
          content="Dini Oyunlar,Dini Etkinlikler ve Dini Özel günler için e-kitaplar içeren web sitesi"
        />
        {/* <meta property="og:image" content="https://example.com/home-image.jpg" /> */}
      </Helmet>
      <Parallax bgImage={camiImage} strength={200}>
        <div style={{ height: 400, marginBottom: 100 }}></div>
      </Parallax>
      <Box
        sx={{
          display: "",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "95vh",
          mb: 10,
          mx: "auto",
          width: "90%",
          marginTop: "100px",
        }}
      >
        <div
          className="baslik"
          style={{ textAlign: "center", marginBottom: 50 }}
        >
          Makalelerimiz{" "}
        </div>
        <Grid2
          container
          spacing={6}
          justifyContent="left"
          alignItems="flex-start"
        >
          {blogs.map((item, index) => (
            <Grid2
              key={index}
              size={{ lg: 5, md: 4, sm: 6, xl: 3, xs: 12 }}
              component={"div"}
              alignItems="flex-start"
            >
              <Card
                sx={{
                  maxWidth: "100%",
                  backgroundColor: theme.palette.secondary.light,
                  boxShadow: theme.shadows[1],
                }}
              >
                <a href={"/makale/" + item.link + "/" + item.guid}>
                  <CardMedia
                    sx={{
                      width: "35%", // Resmi %50 boyutunda gösterir
                      height: 150, // Otomatik yüksekliği sağlar, en boy oranını korur
                      objectFit: "contain", // Resmi kırpmadan içerir
                      display: "block", // Ortalamak için block seviyede gösterir
                      margin: "0 auto",
                    }}
                    image={`../../Upload/Posts/${item.image}`}
                    //image="./img/dramsiz_disiplin.jpg"
                    title={item.title}
                  />
                </a>
                <CardContent
                  sx={{
                    height: {
                      xs: "auto", // small screen (mobile)
                      sm: "auto", // medium screen (tablet)
                      md: "auto", // large screen (desktop)
                      lg: "auto", // extra large screen
                      xl: "auto",
                    },
                  }}
                >
                  <Typography gutterBottom variant="h5" component="div">
                    <a href={"/makale/" + item.link + "/" + item.guid}>
                      {item.title}
                    </a>
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary" }}
                    component={"div"}
                    dangerouslySetInnerHTML={{
                      __html: getSubstringWithoutTags(item.content, 0, 200),
                    }}
                  ></Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <Fab
                    variant="extended"
                    size="medium"
                    color="secondary"
                    href={"/makale/" + item.link + "/" + item.guid}
                  >
                    {/* <Fab variant="extended" size="medium"  color="secondary" href={'/BlogDetails/' + item.guid}> */}
                    Devamı
                  </Fab>
                  {/* <a href={'/blogs/' + item.link}>Devamı...</a> */}
                </CardActions>
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </Box>
    </>
  );
}
