"use client";

import { useEffect, useState } from "react";
import { Parallax } from "react-parallax";
import HeaderImage from "../img/background-istanbul1.png";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid2,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import EbookFilesDto from "../Dtos/EbookFilesDto";
import theme from "../theme/theme";
import EbookDto from "../Dtos/EbookDto";
import ebookImage from "../img/ebook.jpg";
import { Helmet } from "react-helmet-async";

interface PdfViewerProps {
  pdfUrl: string;
  description: string;
}

interface PdfUrl {
  id: string;
  description: string;
  ebookName: string;
  url: string;
}

function PdfViewer({ pdfUrl, description }: PdfViewerProps) {
  return (
    <div style={{ marginBottom: "20px" }}>
      <hr />
      <br />
      <h3>{description}</h3>
      <br />
      <iframe
        src={pdfUrl}
        width="100%"
        height="600px"
        style={{ border: "none" }}
        title="PDF Viewer"
      />
    </div>
  );
}

export default function BlogDetails() {
  const { id } = useParams();
  const [ebookFiles, setEbookFiles] = useState<EbookFilesDto[]>([]);
  const [blogs, setBlogs] = useState<EbookDto[]>([]);
  const [Ebook, setEbook] = useState<EbookDto>();

  const getBlogFunc = async () => {
    try {
      const response = await fetch(
        `https://api.degeregitimiakademisi.com/getebookfiles/${id}`,
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setEbookFiles(data);
    } catch (error) {
      console.log("Error fetching blog content:", error);
    }
  };

  const getEbookDetail = async () => {
    try {
      const response = await fetch(
        `https://api.degeregitimiakademisi.com/getebook/${id}`,
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setEbook(data);
    } catch (error) {
      console.log("Error fetching blog content:", error);
    }
  };

  const getBlogsFunc = async () => {
    try {
      const response = await fetch(
        "https://api.degeregitimiakademisi.com/getebook?ps=5&p=0",
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setBlogs(data);
    } catch (error) {
      console.log("Error fetching blog content:", error);
    }
  };

  useEffect(() => {
    getBlogFunc();
    getEbookDetail();
  }, []);



  

  useEffect(() => {
    if (id) {
      getBlogsFunc();
    }
  }, [id]);

  // useEffect(() => {
  //   if (ebookFiles.length > 0) {
  //     const urls = ebookFiles
  //       .map((item) => {
  //         if (typeof item.fileName === "string") {
  //           const cleanBase64 = item.fileName.replace(
  //             /^data:application\/pdf;base64,/,
  //             ""
  //           );
  //           try {
  //             const byteCharacters = atob(cleanBase64);
  //             const byteNumbers = new Array(byteCharacters.length)
  //               .fill(0)
  //               .map((_, i) => byteCharacters.charCodeAt(i));
  //             const byteArray = new Uint8Array(byteNumbers);
  //             const blob = new Blob([byteArray], { type: "application/pdf" });
  //             return {
  //               id: item.id,
  //               description: item.description,
  //               ebookName: item.ebookName,
  //               url: URL.createObjectURL(blob),
  //             };
  //           } catch (error) {
  //             console.error("Invalid base64 data:", error);
  //             return null;
  //           }
  //         }
  //         return null;
  //       })
  //       .filter((pdf): pdf is PdfUrl => pdf !== null);

  //     setPdfUrls(urls);

  //     // Blob URL’lerin temizlenmesi
  //     return () => urls.forEach((pdf) => URL.revokeObjectURL(pdf.url));
  //   }
  // }, [ebookFiles]);

  return (
    <>
      <Helmet>
      <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1584571816187109"
          crossOrigin="anonymous"
        ></script>
        <title>{Ebook?.title}</title>
        <meta
          name="description"
          content={Ebook?.title}
        />
        <meta
          name="keywords"
          content="dini oyunlar,çocuklar için dini eğitim,dini etkinlikler"
        />
        <meta
          property="og:title"
          content="E-Kitaplar | Değer Eğitimi Akademisi"
        />
        <meta
          property="og:description"
          content="Dini Oyunlar,Dini Etkinlikler ve Dini Özel günler için e-kitaplar içeren web sitesi"
        />
        {/* <meta property="og:image" content="https://example.com/home-image.jpg" /> */}
      </Helmet>
      <Parallax bgImage={HeaderImage} strength={100}>
        <div style={{ height: 500 }}></div>
      </Parallax>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid2 container spacing={7}>
          <Grid2
            size={{ lg: 3 }}
            sx={{
              display: { xs: "none", md: "none", lg: "block", xl: "block" },
            }}
          >
            <Typography
              sx={{ backgroundColor: "#976969", fontSize: 26, color: "white" }}
            >
              Son Eklenen Dosyalar
            </Typography>
            <br />
            <Divider />
            <br />
            <Box
              sx={{
                ml: "2%",
                mb: 8,
                justifyContent: "center",
                display: "contents",
              }}
            >
              {blogs.map((article) => (
                <Box key={article.id}>
                  <Card
                    sx={{
                      maxWidth: "100%",
                      backgroundColor: theme.palette.secondary.light,
                      boxShadow: theme.shadows[1],
                      mb: 3,
                    }}
                  >
                    <a href={`/ekitap/${article.link}/${article.guid}`}>
                      <CardMedia
                        sx={{
                          width: "35%", // Resmi %50 boyutunda gösterir
                          height: 150, // Otomatik yüksekliği sağlar, en boy oranını korur
                          objectFit: "contain", // Resmi kırpmadan içerir
                          display: "block", // Ortalamak için block seviyede gösterir
                          margin: "0 auto",
                        }}
                        image={ebookImage}
                        title={article.title}
                      />
                    </a>
                    <CardContent>
                      <a href={`/ekitap/${article.link}/${article.guid}`}>
                        <Typography variant="body1">{article.title}</Typography>
                      </a>
                    </CardContent>
                  </Card>
                </Box>
              ))}
            </Box>
            <Divider sx={{ backgroundColor: "#976969", fontSize: 26 }} />
          </Grid2>

          <Grid2 size={{ lg: 8 }}>
            <Box>
              {ebookFiles.map((pdf, index) => (
                <div key={pdf.id}>
                  {index === 0 && (
                    <>
                      <div className="baslik">{pdf.description}</div>
                    </>
                  )}
                  <br />
                  <PdfViewer
                    pdfUrl={`../../Upload/Ebook/${pdf.fileName}`}
                    description={pdf.description}
                  />
                </div>
              ))}
            </Box>
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
}
